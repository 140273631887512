<template>
  <div class='indexbox'>
    <div class="searchbox">
      <el-form ref="form" inline size="small">
        <el-row :gutter="8">
          <el-col :lg='6' :md='8' :sm="10" :xs="24">
            <el-form-item label="Binding Time">
              <el-date-picker v-model="timearr" clearable end-placeholder="End" format='yyyy-MM-dd' range-separator="-" start-placeholder="Start" style="max-width:210px" type="daterange" value-format="yyyy-MM-dd"/>
            </el-form-item>
          </el-col>
          <el-col :lg='6' :md='8' :sm="6" :xs="24">
            <el-form-item label="Organization">
              <el-input v-model="company" clearable placeholder="Please Enter" style="width: 180px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg='12' :md='8' :sm="8" :xs="24" class="right">
            <el-form-item>
              <el-button circle icon="el-icon-search" type="primary" @click="pagechage(1)">Query</el-button>
              <el-button type="primary" @click="show=true">Bind API</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tablebox">
      <div v-loading='loading' class="draggable">
        <el-table :data="tableData" stripe>
          <el-table-column v-for="(item,index) in oldList" :key="`col_${index}`" :align="item.align" :fixed='item.fixed' :index='index' :label="item.label" :min-width='item.width' :prop="newList[index].prop" :sortable='item.sortable' show-overflow-tooltip>
            <template v-if="item.prop=='operation'">
              <el-popconfirm title="This Is A Paragraph. Are You Sure To Delete It ?" @confirm='deleteApi(scope.row)'>
                <el-button slot="reference" size="small" type="text">Delete</el-button>
              </el-popconfirm>
              <!-- <el-button type="text" size="small" @click="lookdata(scope.row)">Unbind</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination v-show="total>0" :current-page.sync="page" :page-size="10" :total="total" background layout="total,prev, pager, next" @current-change='pagechage'/>
    </div>
    <el-dialog :title="`Bind API`" :visible.sync="show" width="30%">
      <el-form ref="form" :model="form" :rules="rules" class="formbox">
        <el-form-item prop="api_account">
          <span class="form_tit">API Account*</span>
          <el-input v-model="form.api_account" clearable></el-input>
        </el-form-item>
        <el-form-item prop="api_key">
          <span class="form_tit">API Key*</span>
          <el-input v-model="form.api_key" clearable></el-input>
        </el-form-item>
        <el-form-item class="btnbox">
          <el-button :loading='btn_loading' type="primary" @click.native.prevent="onSubmit">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="info_show" title="details" width="60%">
      <el-form ref="form" :model="info" class="formbox">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item>
              <span class="form_tit">Email</span>
              <el-input v-model="info.email" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <span class="form_tit">Binding time</span>
              <el-input v-model="info.create_time" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <span class="form_tit">Organization name</span>
              <el-input v-model="info.company" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <span class="form_tit">DEAR API Account</span>
              <el-input v-model="info.api_account" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <span class="form_tit">DEAR API KEY</span>
              <el-input v-model="info.api_key" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import BUS from '@/utils/bus.js'
import Sortable from 'sortablejs';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      timearr: [],
      company: '',
      total: 0,
      page: 1,
      tableData: [],
      tableItems: [
        {
          label: 'Email',
          prop: 'email',
          width: '100',
          fixed: 'left',
          align: 'left',
        },
        {
          label: 'Binding Time',
          prop: 'create_time',
          width: '110',
          align: 'center',
          sortable: true,
        },
        {
          label: 'Organization Name',
          prop: 'company',
          width: '120',
          align: 'center',
        },
        {
          label: 'DEAR API Account',
          prop: 'api_account',
          width: '120',
          align: 'center',
        },
        {
          label: 'DEAR API KEY',
          prop: 'api_key',
          width: '120',
          align: 'center',
        },
        // {
        //   label: 'Subscription expiration',
        //   prop: 'end_time',
        //   width: '150',
        //   align: 'center',
        //   sortable: true,
        // },
        // {
        //   label: 'Subscription order quantity',
        //   prop: 'order_num',
        //   width: '90',
        //   align: 'center',
        //   sortable: true,
        // },
        // {
        //   label: 'Used order quantity',
        //   prop: 'has_num',
        //   width: '90',
        //   align: 'center',
        //   sortable: true,
        // },
        // {
        //   label: 'Remaining order quantity',
        //   prop: 'remain_num',
        //   width: '90',
        //   align: 'center',
        //   sortable: true,
        // },
        {
          label: 'Operation',
          prop: 'operation',
          width: '80',
          fixed: 'right',
          align: 'right',
        },
      ],
      oldList: [],
      newList: [],
      loading: false,
      // 添加
      show: false,
      btn_loading: false,
      form: {
        api_account: '',
        api_key: '',
      },
      rules: {
        api_account: [
          {required: true, trigger: "blur", message: 'Please Enter'}
        ],
        api_key: [
          {required: true, trigger: "blur", message: 'Please Enter'}
        ],
      },
      // 详情
      info_show: false,
      info: {},
    };
  },
  computed: {
    ...mapGetters(['api_id']),
  },
  methods: {
    getlist() {
      this.loading = true
      this.$post({
        url: "/app/member_api/apiList",
        data: {
          page: this.page,
          start_time: this.timearr && this.timearr.length ? this.timearr[0] : '',
          end_time: this.timearr && this.timearr.length ? this.timearr[1] : '',
          company: this.company,
        },
        success: (res) => {
          this.tableData = res.data.data
          this.total = res.data.total
          this.page = res.data.current_page
          setTimeout(() => {
            this.loading = false
          }, 200);
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    pagechage(page) {
      this.page = page
      this.tableData = []
      this.getlist()
    },
    // 添加
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.btn_loading = true
          this.$post({
            url: "/app/member_api/bindApi",
            data: this.form,
            success: (res) => {
              this.$message({
                message: 'Success',
                type: 'success'
              });
              this.show = false
              BUS.$emit('apilist')
              setTimeout(() => {
                this.pagechage(1)
                this.btn_loading = false
                location.reload();
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.btn_loading = false
              }, 1500);
            },
          })
        }
      });
    },
    // 列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector('.draggable .el-table__header-wrapper tr');
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: evt => {
          const oldItem = this.newList[evt.oldIndex];
          this.newList.splice(evt.oldIndex, 1);
          this.newList.splice(evt.newIndex, 0, oldItem);
        }
      });
    },
    deleteApi(data) {
      this.loading = true
      this.$post({
        url: "/app/member_api/deleteApi",
        data: {
          id: data.id
        },
        success: (res) => {
          this.$notify({
            title: 'Success',
            type: 'success'
          });
          BUS.$emit('apilist')
          BUS.$emit('change_api')
          setTimeout(() => {
            this.pagechage(1)
          }, 1300);
        },
        tip: () => {
          this.loading = false
        },
      })
    },
  },
  mounted() {
    this.oldList = JSON.parse(JSON.stringify(this.tableItems))
    this.newList = JSON.parse(JSON.stringify(this.tableItems))
    this.columnDrop()
  },
  created() {
    this.getlist()
  },
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  height: 100%;

  .searchbox {
    width: 100%;
    padding: 10px 20px 0px;
    background: #fff;
    border-bottom: 1px solid #babdcd;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /deep/ .el-row {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    /deep/ .el-form {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-form-item {
        margin: 0 20px 10px 0 !important;
      }

      .el-input__inner {
        // background: #edeff8;
        input {
          background: none;
        }
      }
    }

    /deep/ .el-button {
      background-color: #d6f3e3;
      border-radius: 10px;
      color: #3b3b3b;
      border-color: #d6f3e3;
      align-self: flex-end;
    }
  }

  .tablebox {
    width: 100%;
    padding: 20px;
  }

  .formbox {
    /deep/ .el-form-item {
      margin-bottom: 30px !important;

      .el-form-item__content {
        width: 100%;

        .el-button {
          width: 100%;
          height: 48px;
          background: $color;
          border-radius: 5px;
          border-color: $color;
        }
      }
    }
  }
}
</style>
